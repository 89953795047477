// Link Dropdown

var jiren_link_dropdown = (function() {
  function link_dropdown() {
    $(".link-dropdown-toggle").click(function() {
      var dropdown = $(this)
        .parent()
        .find(".link-dropdown-content");

      $(".link-dropdown-content")
        .not(dropdown)
        .each(function() {
          $(this).fadeOut(50);
        });

      if (dropdown.css("display") != "none") {
        dropdown.fadeOut(50);
      } else {
        dropdown.fadeIn(50);
      }

      return false;
    });

    $(document).on("click", ".site-container", function() {
      $(".link-dropdown-content").fadeOut(50);
    });
  }

  return {
    init: function() {
      link_dropdown();
    }
  };
})(this);

document.addEventListener("turbolinks:load", jiren_link_dropdown.init);
