// Bohemia Profile Form
// NOTE: I need to refactor error messages to support localization.

var bohemiaProfileForm = (function() {
  function emailCheck() {
    var userEmail = $(".profile-form #user_email");
    userEmail.on("blur", function() {
      if (userEmail.val() == "") {
        $(".profile-form #email-check").html("Email cannot be blank.");
      } else if (!checkEmailRegex(userEmail.val())) {
        $(".profile-form #email-check").html("Email is invalid.");
      } else {
        $(".profile-form #email-check").html("");
      }
    });
  }

  function passwordCheck() {
    var userPassword = $(".profile-form #user_password");
    userPassword.on("input", function() {
      $.ajax({
        url: "../util/calculate_password_entropy",
        data: { password: userPassword.val() },
        dataType: "json",
        success: function(json) {
          $(".profile-form #password-check").attr("class", "");
          $(".profile-form #password-check").addClass(json["status"]);
          $(".profile-form #password-check").html(json["msg"]);
        }
      });
    });
  }

  function passwordConfirmationCheck() {
    var userPassword = $(".profile-form #user_password");
    var userPasswordConfirmation = $(
      ".profile-form #user_password_confirmation"
    );
    userPasswordConfirmation.on("input", function() {
      if (
        userPassword.val() != "" &&
        userPassword.val() != userPasswordConfirmation.val()
      ) {
        $(".profile-form #password-confirmation-check").html(
          "Passwords are not the same."
        );
      } else {
        $(".profile-form #password-confirmation-check").html("");
      }
    });
  }

  function userProfileCityCheck() {
    var userCity = $(".profile-form #user_user_profile_attributes_city");
    userCity.on("blur", function() {
      if (userCity.val() == "") {
        $(".profile-form #city-check").html("City cannot be blank.");
      } else {
        $(".profile-form #city-check").html("");
      }
    });
  }

  function userProfileZipCodeCheck() {
    var userZipCode = $(".profile-form #user_user_profile_attributes_zip_code");
    userZipCode.on("blur", function() {
      if (userZipCode.val() == "") {
        $(".profile-form #zip-code-check").html("Zip Code cannot be blank.");
      } else if (/\D+/.test(userZipCode.val())) {
        $(".profile-form #zip-code-check").html(
          "Zip Code only accepts numbers."
        );
      } else if (userZipCode.val().length > 5) {
        $(".profile-form #zip-code-check").html("Zip Code is too long.");
      } else {
        $(".profile-form #zip-code-check").html("");
      }
    });
  }

  function userProfilePhoneNumberFormat() {
    var userPhoneNumber = $(
      ".profile-form #user_user_profile_attributes_phone_number"
    );
    userPhoneNumber.on("blur", function() {
      formattedPhoneNumber = formatPhone(userPhoneNumber.val());
      userPhoneNumber.val(formattedPhoneNumber);
    });
  }

  return {
    init: function() {
      emailCheck();
      passwordCheck();
      passwordConfirmationCheck();
      userProfileCityCheck();
      userProfileZipCodeCheck();
      userProfilePhoneNumberFormat();
    }
  };
})(this);

document.addEventListener("turbolinks:load", bohemiaProfileForm.init);
