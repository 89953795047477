// Bohemia Account Form
// NOTE: I need to refactor error messages to support localization.

var bohemiaAccountForm = (function() {
  function usernameCheck() {
    var accountUsername = $(".account-form #account_username");
    accountUsername.on("blur", function() {
      if (accountUsername.val() == "") {
        $(".account-form #username-check").html("Username cannot be blank.");
      } else {
        $(".account-form #username-check").html("");
      }
    });
  }

  function emailCheck() {
    var accountEmail = $(".account-form #account_email");
    accountEmail.on("blur", function() {
      if (accountEmail.val() == "") {
        $(".account-form #email-check").html("Email cannot be blank.");
      } else if (!checkEmailRegex(accountEmail.val())) {
        $(".account-form #email-check").html("Email is invalid.");
      } else {
        $(".account-form #email-check").html("");
      }
    });
  }

  function passwordCheck() {
    var accountPassword = $(".account-form #account_password");
    accountPassword.on("input", function() {
      $.ajax({
        url: "../../util/calculate_password_entropy",
        data: { password: accountPassword.val() },
        dataType: "json",
        success: function(json) {
          $(".account-form #password-check").attr("class", "");
          $(".account-form #password-check").addClass(json["status"]);
          $(".account-form #password-check").html(json["msg"]);
        }
      });
    });
  }

  function passwordConfirmationCheck() {
    var accountPassword = $(".account-form #account_password");
    var accountPasswordConfirmation = $(
      ".account-form #account_password_confirmation"
    );
    accountPasswordConfirmation.on("input", function() {
      if (
        accountPassword.val() != "" &&
        accountPassword.val() != accountPasswordConfirmation.val()
      ) {
        $(".account-form #password-confirmation-check").html(
          "Passwords are not the same."
        );
      } else {
        $(".account-form #password-confirmation-check").html("");
      }
    });
  }

  return {
    init: function() {
      usernameCheck();
      emailCheck();
      passwordCheck();
      passwordConfirmationCheck();
    }
  };
})(this);

document.addEventListener("turbolinks:load", bohemiaAccountForm.init);
