// Bohemia Admin Sidebar

var bohemiaAdminSidebar = (function() {
  function toggleSidebar() {
    var sidebarToggle = $("#cp-sidebar-toggle");
    sidebarToggle.on("click", function(e) {
      e.preventDefault();
      $.ajax({
        url: "/util/set_sidebar_styles",
        dataType: "json",
        success: function(json) {
          $(".cp-container").removeClass(json["old_sidebar_class"]);
          $(".cp-container").addClass(json["new_sidebar_class"]);
          sidebarToggle.removeClass(json["old_nav_class"]);
          sidebarToggle.addClass(json["new_nav_class"]);
        }
      });
    });
  }

  return {
    init: function() {
      toggleSidebar();
    }
  };
})(this);

document.addEventListener("turbolinks:load", bohemiaAdminSidebar.init);
