// Tabs

var jiren_tabs = (function () {
  function tabs() {
    $(".tabs-toggle").click(function () {
      var tab_id = $(this).attr("data-tab");
      $(this).addClass("active");
      $(this).siblings().removeClass("active");
      $(this).parents(".tabs").find(".tabs-content").removeClass("active");
      $(this)
        .parents(".tabs")
        .find("#" + tab_id)
        .addClass("active");
    });
  }

  // Set Width of .tabs-list
  // Fallback for browsers that don't support "width: max-content;"
  function setTabWidth() {
    var width = 0;
    $(".tabs .tabs-toggle").each(function () {
      width += $(this).outerWidth(true);
    });
    $(".tabs .tabs-list").css("width", width + 50);
  }

  return {
    init: function () {
      tabs();
      setTabWidth();
    },
  };
})(this);

document.addEventListener("turbolinks:load", jiren_tabs.init);
