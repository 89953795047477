// Offcanvas Menu

var jiren_offcanvas_menu = (function() {
  function offcanvas_menu() {
    $(
      ".site-offcanvas-left-toggle, .site-offcanvas.left .site-offcanvas-controls-close"
    ).click(function() {
      if ($(".site-container").hasClass("site-offcanvas-left-open")) {
        $(".site-container").removeClass("site-offcanvas-left-open");
        $(".site-offcanvas-overlay").fadeOut(200);
      } else {
        $(".site-container").addClass("site-offcanvas-left-open");
        $(".site-offcanvas-overlay").fadeIn(200);
      }
    });

    $(
      ".site-offcanvas-right-toggle, .site-offcanvas.right .site-offcanvas-controls-close"
    ).click(function() {
      if ($(".site-container").hasClass("site-offcanvas-right-open")) {
        $(".site-container").removeClass("site-offcanvas-right-open");
        $(".site-offcanvas-overlay").fadeOut(200);
      } else {
        $(".site-container").addClass("site-offcanvas-right-open");
        $(".site-offcanvas-overlay").fadeIn(200);
      }
    });

    $(".site-offcanvas-overlay").click(function() {
      $(".site-container").removeClass("site-offcanvas-left-open");
      $(".site-container").removeClass("site-offcanvas-right-open");
      $(".site-offcanvas-overlay").fadeOut(200);
    });
  }

  return {
    init: function() {
      offcanvas_menu();
    }
  };
})(this);

document.addEventListener("turbolinks:load", jiren_offcanvas_menu.init);
