// Dynamic Tabs

var jiren_dynamic_tabs = (function() {
  function dynamic_tabs() {
    $(".dynamic-tabs-toggle-chevron").click(function() {
      if ($(".dynamic-tabs-list").hasClass("closed")) {
        $(".dynamic-tabs-list").removeClass("closed");
        $(".dynamic-tabs-list").addClass("open");
      } else {
        $(".dynamic-tabs-list").removeClass("open");
        $(".dynamic-tabs-list").addClass("closed");
      }
    });
  }

  return {
    init: function() {
      dynamic_tabs();
    }
  };
})(this);

document.addEventListener("turbolinks:load", jiren_dynamic_tabs.init);
