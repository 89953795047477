// Fixed Header

var stickyHeader = function() {
  var isChrome = /chrom(e|ium)/.test(navigator.userAgent.toLowerCase());

  var headerHeight = $("header").height();

  var nextSibling = $("header").next();

  // The position of top of page in browser window
  var windowTopPosition = $(window).scrollTop();

  // The thing that sticks
  var stickyContainer = $("header");

  if (stickyContainer.hasClass("fixed")) {
    windowTopPosition += headerHeight;
  }

  if (windowTopPosition > headerHeight) {
    if (!stickyContainer.hasClass("fixed")) {
      stickyContainer.addClass("fixed");
      stickyContainer.slideDown(400);

      if (!isChrome) {
        nextSibling.css("margin-top", headerHeight);
      }
    }
  } else {
    stickyContainer.removeClass("fixed");
    stickyContainer.css("display", "");

    if (!isChrome) {
      nextSibling.css("margin-top", 0);
    }
  }
};

$(window).scroll(stickyHeader);
