// Switch Toggle

var jiren_switch_toggle = (function() {
  function switchToggle() {
    $(".switch-toggle").click(function() {
      id = $(this).data("togglediv");

      togglediv = $("#" + id);

      $(".switch-content")
        .not(togglediv)
        .each(function() {
          $(this).slideUp(100);
        });

      if (togglediv.css("display") != "none") {
        togglediv.slideUp(100);
      } else {
        togglediv.slideDown(100);
      }

      return false;
    });

    $(document).on("click", "body", function() {
      $(".switch-content").fadeOut(50);
    });
  }

  return {
    init: function() {
      switchToggle();
    }
  };
})(this);

document.addEventListener("turbolinks:load", jiren_switch_toggle.init);
