// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("jquery");
require("@rails/actiontext");

require("vendor/chart");
require("vendor/cocoon");
require("vendor/jquery.tokeninput");
require("vendor/trix");

require("components/accordion");
require("components/dynamic_tabs");
require("components/fixed_header");
require("components/link_dropdown");
require("components/menu_list");
require("components/offcanvas_menu");
require("components/panel_responsive");
require("components/scrollable");
require("components/site_canvas");
require("components/switch_toggle");
require("components/tabs");
require("components/utils");
require("components/href_to_clipboard");
require("components/load_in_modal");

require("title/account_form");
require("title/admin_sidebar");
require("title/profile_form");
require("title/saved_feasibility_share_page");
require("title/utils");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// bohemia

import toastr from "toastr";
toastr.options = {
  closeButton: true,
};
global.toastr = toastr;

import flatpickr from "flatpickr";

// Let's show it always!
Turbolinks.setProgressBarDelay(1);
