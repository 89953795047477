// Site Canvas

// Toggle click
$(document).on("click", ".site-menu-toggle", function() {
  if ($('.site-canvas').hasClass('show-menu')) {
    $('.site-canvas').removeClass('show-menu');
  } else {
    $('.site-canvas').addClass('show-menu');
  }
});

// Toggle hover
// $(document).on("mouseover", ".site-menu-inner ul", function() {
//   $('.site-canvas').addClass('show-menu');
//   return false
// });

// Close
$(document).on("click", ".site-content", function() {
  if ($('.site-canvas').hasClass('show-menu')) {
    // Do things on Nav Close
    $('.site-canvas').removeClass('show-menu');
  }
});

// $(document).on("mouseover", ".site-menu-inner", function() {
//   $('.site-canvas').addClass('show-menu');
// });

$(document).on("click", ".site-menu-inner", function() {
  $('.site-canvas').addClass('show-menu');
});
