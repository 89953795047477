// Menu List

var jiren_menu_list = (function() {
  function menu_list() {
    // Toggle
    $(".menu-list.collapse.horizontal .has-submenu .submenu-toggle").click(
      function() {
        // horizontal specific
        var siblingMenus = $(this)
          .parent(".top-level")
          .siblings(".open");
        siblingMenus.removeClass("open");
        siblingMenus.find(".submenu").slideUp(50);
        siblingMenus.find(".submenu-toggle-icon").removeClass("open");

        return false;
      }
    );

    // Toggle
    $(".menu-list.collapse .has-submenu .submenu-toggle").click(function() {
      // submenu and submenu-toggle are on the same level
      var submenu = $(this)
        .siblings(".submenu")
        .first();
      if (submenu.css("display") != "none") {
        $(this)
          .parent()
          .removeClass("open");
        $(this)
          .children(".submenu-toggle-icon")
          .removeClass("open");
        submenu.slideUp(50);
      } else {
        $(this)
          .parent()
          .addClass("open");
        $(this)
          .children(".submenu-toggle-icon")
          .addClass("open");
        submenu.slideDown(50);
      }

      return false;
    });

    function resetMenus() {
      // hide all menus
      $(".menu-list.collapse .has-submenu .submenu").slideUp(50);

      // resets chevrons
      if ($(".menu-list.collapse .has-submenu").hasClass("open")) {
        $(".menu-list.collapse .has-submenu.open")
          .find(".submenu-toggle-icon.open")
          .removeClass("open");
        $(".menu-list.collapse .has-submenu.open").removeClass("open");
      }
    }

    $(".menu-list.collapse .has-submenu .submenu").on("click", function(e) {
      e.stopPropagation();
    });
    $(document).on("click", ".site-container", function() {
      resetMenus();
    });
  }

  return {
    init: function() {
      menu_list();
    }
  };
})(this);

document.addEventListener("turbolinks:load", jiren_menu_list.init);
