// Bohemia Saved Feasibility Page

var bohemiaSavedFeasibilityPage = (function() {
  function toggleShareForm() {
    var shareFormToggle = $("#share-form-toggle");
    var shareForm = $("#share-form");
    shareFormToggle.on("click", function(e) {
      e.preventDefault();

      shareForm.toggle();
    });
  }

  return {
    init: function() {
      toggleShareForm();
    }
  };
})(this);

document.addEventListener("turbolinks:load", bohemiaSavedFeasibilityPage.init);
