
var href_to_clipboard = (function () {
  function bindLinks(){
    $('a.href-to-clipboard').on('click', function (event) {
      event.preventDefault();
      const cb = navigator.clipboard
      const url = event.target.href
      cb.writeText(url).then(() => toastr.success('URL copied to clipboard'))
    })
  }

  return {
    init: function () {
      bindLinks();
    }
  };
})(this);

document.addEventListener('turbolinks:load', href_to_clipboard.init);
