// Accordion

var jiren_accordion = (function() {
  function accordion() {
    $(".accordion")
      .find(".accordion-toggle")
      .click(function() {
        var accordionContent = $(this).next(".accordion-content");

        if (accordionContent.css("display") != "none") {
          $(this).removeClass("open");
          accordionContent.slideUp(50);
        } else {
          $(this).addClass("open");
          accordionContent.slideDown(50);
        }
      });
  }

  return {
    init: function() {
      accordion();
    }
  };
})(this);

document.addEventListener("turbolinks:load", jiren_accordion.init);
