// Panel Responsive

var jiren_panel_responsive = (function() {
  function panel_responsive() {
    // Copy contents of large to small div
    $(".panel-responsive").each(function() {
      var small = $(this).find(".panel-responsive-small");
      var large = $(this).find(".panel-responsive-large");
      $(small).html($(large).html());
    });

    // Toggle
    $(".panel-responsive-toggle").click(function() {
      var small = $(this)
        .parent()
        .find(".panel-responsive-small .content");
      var alt = $(this)
        .parent()
        .find(".panel-responsive-alt .content");

      $(alt).each(function() {
        $(this).slideUp(0);
        $(this)
          .parent()
          .parent()
          .find(".panel-responsive-toggle-alt-icon")
          .removeClass("open");
      });

      if (small.css("display") != "none") {
        $(this)
          .find(".panel-responsive-toggle-icon")
          .removeClass("open");
        small.slideUp(50);
      } else {
        $(this)
          .find(".panel-responsive-toggle-icon")
          .addClass("open");
        small.slideDown(50);
      }

      return false;
    });

    // Toggle alt
    $(".panel-responsive-toggle-alt").click(function() {
      var small = $(this)
        .parent()
        .find(".panel-responsive-small .content");
      var alt = $(this)
        .parent()
        .find(".panel-responsive-alt .content");

      $(small).each(function() {
        $(this).slideUp(0);
        $(this)
          .parent()
          .parent()
          .find(".panel-responsive-toggle-icon")
          .removeClass("open");
      });

      if (alt.css("display") != "none") {
        $(this)
          .find(".panel-responsive-toggle-alt-icon")
          .removeClass("open");
        alt.slideUp(50);
      } else {
        $(this)
          .find(".panel-responsive-toggle-alt-icon")
          .addClass("open");
        alt.slideDown(50);
      }

      return false;
    });

    // Close on body click/tap
    // Needs Modernizr and <style>.touch .page { cursor: pointer; }</style>
    $(".panel-responsive-small, .panel-responsive-alt").on("click", function(
      e
    ) {
      e.stopPropagation();
    });

    $(document).on("click", ".site-container", function() {
      $(
        ".panel-responsive-small .content, .panel-responsive-alt .content"
      ).slideUp(50);

      if (
        $(".panel-responsive .panel-responsive-toggle-icon").hasClass("open")
      ) {
        $(".panel-responsive .panel-responsive-toggle-icon.open").removeClass(
          "open"
        );
      }

      if (
        $(".panel-responsive .panel-responsive-toggle-alt-icon").hasClass(
          "open"
        )
      ) {
        $(
          ".panel-responsive .panel-responsive-toggle-alt-icon.open"
        ).removeClass("open");
      }
    });
  }

  return {
    init: function() {
      panel_responsive();
    }
  };
})(this);

document.addEventListener("turbolinks:load", jiren_panel_responsive.init);
