// NOTE: http://railscasts.com/episodes/197-nested-model-form-part-2
function add_fields(link, association, content) {
  var new_id = new Date().getTime();
  var regexp = new RegExp("new_" + association, "g");
  $(link).before(content.replace(regexp, new_id));
}

// NOTE: http://railscasts.com/episodes/197-nested-model-form-part-2
function remove_fields(link) {
  $(link)
    .prev("input[type=hidden]")
    .val("1");
  $(link)
    .closest(".fields")
    .hide();
}
